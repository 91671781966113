import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Col, message, Row, Skeleton, Spin } from 'antd'
import { injectIntl } from 'react-intl'
import ReactToPrint from 'react-to-print'
import dayjs from 'dayjs'
import _ from 'lodash'

import { cashFlowDetailReports, generalLedgerReports } from 'utils/apis'
import { generalLedgerCategoryReports } from 'utils/apis/reports'
import { currency, formatDate, formatDateText } from 'utils/formatting'
import Filter from 'components/apps/Reports/AccountTransactions/Filter'
import PrintTable from 'components/apps/Reports/AccountTransactions/Print'
import { cleanBlankValue } from 'utils/helper'
import { LinkTransType, PageHeader, Pagination, Print } from 'components/UI'
import Charts from 'components/apps/Reports/AccountTransactions/Charts'
import { LoadingInvalidData } from 'components/apps/FinanceProducts/FinanceProductDetail/StockMovements'

const initialParams = {
  date_to: dayjs(),
  date_from: dayjs().subtract(1, 'months'),
  tags: [],
  tags_setting: null,
  sources_id: [],
  search: '',
}

const formatNumber = (value, reformatOnNegative = true) => {
  if (reformatOnNegative && value <= -1) {
    return `(${currency(value, false, true, true)})`.replace('-', '')
  }

  const formatted = currency(value, false, true, true)

  return formatted === '-' ? 0 : formatted
}

class AccountTransactionsReports extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      params: initialParams,
      page: 1,
      total: 0,
      data: [],
      per_page: 100,
      journal_total: {},
      summary: true,
      isShowFilter: false,
      accountName: {},
      title_lang: '',
      showGraph: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
    if (this.props.loadedInit) {
      this.setParams({
        date_to: dayjs(this.props.params.date_to, 'YYYY-MM-DD').isValid()
          ? dayjs(this.props.params.date_to, 'YYYY-MM-DD')
          : dayjs(),
        date_from: dayjs(this.props.params.date_from, 'YYYY-MM-DD').isValid()
          ? dayjs(this.props.params.date_from, 'YYYY-MM-DD')
          : dayjs().subtract(1, 'months'),
        tags: this.parseTags(),
        tags_setting: this.props.params.tags_setting,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.params, this.props.params)) {
      this.setParams({
        date_to: dayjs(this.props.params.date_to, 'YYYY-MM-DD').isValid()
          ? dayjs(this.props.params.date_to, 'YYYY-MM-DD')
          : dayjs(),
        date_from: dayjs(this.props.params.date_from, 'YYYY-MM-DD').isValid()
          ? dayjs(this.props.params.date_from, 'YYYY-MM-DD')
          : dayjs().subtract(1, 'months'),
        tags: this.parseTags(),
        tags_setting: this.props.params.tags_setting,
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onSearchDebounce = _.debounce((params) => this.setParams(params), 1000)

  getParams = () => {
    const { page, per_page } = this.state
    const {
      date_to,
      date_from,
      tags,
      tags_setting: tagsSetting,
      sources_id,
      search,
    } = this.state.params
    const { params } = this.props

    const useGlCatId = params.isCategory || params.cashFlowDetail

    const payload = {
      [useGlCatId ? 'gl_cat_id' : 'account_id']: params.id,
      date_from: dayjs(date_from).isValid() ? dayjs(date_from).format('YYYY-MM-DD') : null,
      date_to: dayjs(date_to).isValid() ? dayjs(date_to).format('YYYY-MM-DD') : null,
      tags: Array.isArray(tags) ? tags.join(',') : null,
      tags_setting: tagsSetting,
      page,
      per_page,
    }

    if (params.remove_closing) {
      payload.remove_closing = 1
    }

    // Untuk paramms tambahan di arus kas jika menggunakan method=direct
    if (params.bankonly) {
      payload.bankonly = 1
    }

    if (!params.isCategory && !params.cashFlowDetail) {
      payload.sources_id = sources_id.join(',')
      payload.search = search
    }

    return cleanBlankValue(payload)
  }

  searchHandler = (event) => {
    const search = event.target.value

    this.onSearchDebounce({ search })
  }

  loadData = () => {
    const payload = this.getParams()
    if (!(payload.date_from && payload.date_to)) return

    this.setState({
      loading: true,
    })

    let getReports

    if (this.props.params.isCategory) {
      getReports = generalLedgerCategoryReports
    } else if (this.props.params.cashFlowDetail) {
      getReports = cashFlowDetailReports
    } else {
      getReports = generalLedgerReports
    }

    getReports(payload)
      .then((response) => {
        if (this._isMounted) {
          if (response.data.success) {
            const { data } = response.data

            let isInvalid = false
            if (_.isArray(data.data)) {
              data.data.forEach((row) => {
                if (_.isArray(row.data)) {
                  row.data.forEach((item) => {
                    if (item.valid === false) isInvalid = true
                  })
                }
              })
            }

            if (isInvalid) setTimeout(() => this.loadData(), 10000)

            this.setState({
              summary: false,
              loading: false,
              data: data.data || [],
              total: data.total || 0,
              page: data.current_page || 1,
              per_page: data.per_page || 0,
              journal_total: data.journal_total,
              accountName: data.account_name,
              title_lang: data.title_lang,
            })
          } else {
            message.error(response.data.message)
            this.setState({ loading: false, total: 0, page: 1, summary: false })
          }
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          message.error(
            (error && error.message) || this.props.intl.formatMessage({ id: 'button.error_data_' }),
          )
          this.setState({
            loading: false,
            total: 0,
            page: 1,
            summary: false,
          })
        }
      })
  }

  toggleShowFilter = () => {
    this.setState((prevState) => ({
      isShowFilter: !prevState.isShowFilter,
    }))
  }

  setParams = (params) => {
    this.setState((prevState) => {
      return {
        params: {
          ...prevState.params,
          ...params,
        },
        page: 1,
        total: 0,
      }
    }, this.loadData)
  }

  renderLoading = () => {
    return (
      <React.Fragment>
        <Row style={{ marginTop: 25, overflow: 'hidden' }}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Skeleton active />
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  getHeader = () => {
    const { intl, params } = this.props
    const { accountName, title_lang } = this.state
    const nameAccount = accountName ? accountName.title : ''
    const codeAccount = accountName ? accountName.code : ''

    if (params.isCategory || params.cashFlowDetail) return <h1>{title_lang}</h1>

    return (
      <Fragment>
        <h1>
          {intl.formatMessage(
            { id: 'report.account_transactions_title' },
            { account: nameAccount },
          )}
        </h1>
        <h3>{codeAccount}</h3>
      </Fragment>
    )
  }

  parseTags = () => {
    let { tags } = this.props.params
    if (this.props.params.tags && typeof this.props.params.tags === 'string') {
      tags = this.props.params.tags.split(',')
    }

    return tags || []
  }

  clearFilter = () => {
    const { params } = this.state

    const unClearParams = {
      date_from: dayjs(params.date_from).format('YYYY-MM-DD'),
      date_to: dayjs(params.date_to).format('YYYY-MM-DD'),
      tags: params.tags,
      tags_setting: params.tags_setting,
      search: params.search,
    }

    return {
      onClear: () => {
        this.setParams({
          ...initialParams,
          ...unClearParams,
          date_from: dayjs(params.date_from),
          date_to: dayjs(params.date_to),
        })
      },
      showClearBtn: !_.isEqual(
        cleanBlankValue({
          ...params,
          ...unClearParams,
        }),
        cleanBlankValue({
          ...initialParams,
          ...unClearParams,
        }),
      ),
    }
  }

  getBody = () => {
    const { data, journal_total } = this.state
    const { intl, params } = this.props
    const formattedData = Object.keys(data) || []
    const body = []

    if (params.isCategory || params.cashFlowDetail) {
      formattedData.forEach((item, index) => {
        body.push(
          <React.Fragment key={data[item].id}>
            <tr className="ant-table-row">
              <td colSpan={8}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <h3 className="pr-3">{data[item].name || '-'}</h3>
                  <h4>({data[item].ref_code || '-'})</h4>
                </div>
              </td>
            </tr>
            {(data[item].data || []).map((v) => {
              const { trans_type_id } = v
              return (
                <tr key={v.id} className="ant-table-row">
                  <td>{formatDate(v.trans_date)}</td>
                  <td>{v.source}</td>
                  <td>
                    <LinkTransType
                      data={{
                        id: v.tran_id,
                        accountId: data[item].id,
                        warehouseId: data[item].warehouse_id || 0,
                      }}
                      transTypeId={trans_type_id}
                      title={v.desc}
                    />
                  </td>
                  {/* <td>{link.length ? <a href="#" onClick={(e) => { e.preventDefault(); history.push(link) }}>{v.desc}</a> : v.desc}</td> */}
                  <td>{v.reference}</td>
                  <td>{v.ref_number}</td>
                  <td style={{ textAlign: 'right' }}>{formatNumber(v.debit)}</td>
                  <td style={{ textAlign: 'right' }}>{formatNumber(v.credit, false)}</td>
                  {!params.cashFlowDetail ? (
                    <td style={{ textAlign: 'right' }}>{formatNumber(v.balance)}</td>
                  ) : null}
                </tr>
              )
            })}
            <tr className="ant-table-row">
              <td colSpan={5}>
                <h5>{intl.formatMessage({ id: 'report.closing_balance' })}</h5>
              </td>
              <td style={{ textAlign: 'right' }}>
                <h5>{formatNumber(data[item].closing_debit)}</h5>
              </td>
              <td style={{ textAlign: 'right' }}>
                <h5>{formatNumber(data[item].closing_credit, false)}</h5>
              </td>
              {!params.cashFlowDetail ? (
                <td style={{ textAlign: 'right' }}>
                  <h5>{formatNumber(data[item].closing_balance)}</h5>
                </td>
              ) : null}
            </tr>
            {(Object.keys(data) || []).length !== index + 1 && (
              <tr className="ant-table-row">
                <td colSpan={8} />
              </tr>
            )}
          </React.Fragment>,
        )
      })
      body.push(
        <React.Fragment key="top-list">
          <tr className="ant-table-row">
            <td colSpan={!params.cashFlowDetail ? 8 : 7} />
          </tr>
          <tr className="ant-table-row">
            <td colSpan={5}>
              <h5>{intl.formatMessage({ id: 'report.total' })}</h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>{formatNumber(journal_total.total_debit, false)}</h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>{formatNumber(journal_total.total_credit, false)}</h5>
            </td>
            {!params.cashFlowDetail ? <td /> : null}
          </tr>
        </React.Fragment>,
      )

      return body
    }

    formattedData.forEach((item, index) => {
      body.push(
        <React.Fragment key={data[item].name}>
          <tr className="ant-table-row">
            <td colSpan={5}>
              <h5>{intl.formatMessage({ id: 'report.opening_balance' })}</h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>{formatNumber(data[item].opening_debit)}</h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>{formatNumber(data[item].opening_credit, false)}</h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>{formatNumber(data[item].opening_balance)}</h5>
            </td>
          </tr>
          {(data[item].data || []).map((v) => {
            return (
              <tr key={v.id} className="ant-table-row">
                <td>{dayjs(v.trans_date, 'YYYY-MM-DD').format(formatDateText())}</td>
                <td>{v.source}</td>
                <td>
                  <LinkTransType
                    data={{
                      id: v.tran_id,
                      accountId: v.account_id,
                      warehouseId: data[item].warehouse_id || 0,
                    }}
                    transTypeId={v.trans_type_id}
                    title={v.desc}
                  />
                </td>
                <td>{v.reference}</td>
                <td>{v.ref_number}</td>
                <td style={{ textAlign: 'right' }}>{formatNumber(v.debit)}</td>
                <td style={{ textAlign: 'right' }}>{formatNumber(v.credit, false)}</td>
                <td style={{ textAlign: 'right' }}>
                  {v.valid === false ? (
                    <div style={{ width: 50 }} className="ml-auto">
                      <LoadingInvalidData />
                    </div>
                  ) : (
                    formatNumber(v.balance)
                  )}
                </td>
              </tr>
            )
          })}
          <tr className="ant-table-row">
            <td colSpan={5}>
              <h5>{intl.formatMessage({ id: 'report.closing_balance' })}</h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>{formatNumber(data[item].closing_debit)}</h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>{formatNumber(data[item].closing_credit, false)}</h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>{formatNumber(data[item].closing_balance)}</h5>
            </td>
          </tr>
          {(Object.keys(data) || []).length !== index + 1 && (
            <tr className="ant-table-row">
              <td colSpan={8} />
            </tr>
          )}
        </React.Fragment>,
      )
    })
    body.push(
      <React.Fragment key="top-list">
        <tr className="ant-table-row">
          <td colSpan={8} />
        </tr>
        <tr className="ant-table-row">
          <td colSpan={5}>
            <h5>{intl.formatMessage({ id: 'report.total' })}</h5>
          </td>
          <td style={{ textAlign: 'right' }}>
            <h5>{formatNumber(journal_total.total_debit, false)}</h5>
          </td>
          <td style={{ textAlign: 'right' }}>
            <h5>{formatNumber(journal_total.total_credit, false)}</h5>
          </td>
          <td />
        </tr>
      </React.Fragment>,
    )

    return body
  }

  toggleGraphHandler = () => {
    this.setState((prevState) => ({ showGraph: !prevState.showGraph }))
  }

  render() {
    const { params, data, page, total, per_page, loading, journal_total, showGraph } = this.state
    const { intl, isMobileView } = this.props

    const columns = [
      {
        title: intl.formatMessage({ id: 'report.date' }),
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: intl.formatMessage({ id: 'report.source' }),
        dataIndex: 'source',
        key: 'source',
      },
      {
        title: intl.formatMessage({ id: 'report.description' }),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: intl.formatMessage({ id: 'report.reference' }),
        dataIndex: 'reference',
        key: 'reference',
      },
      {
        title: intl.formatMessage({ id: 'report.number' }),
        dataIndex: 'ref_number',
        key: 'ref_number',
      },
      {
        title: intl.formatMessage({ id: 'report.debit' }),
        dataIndex: 'debit',
        key: 'debit',
        textAlign: 'right',
      },
      {
        title: intl.formatMessage({ id: 'report.credit' }),
        dataIndex: 'credit',
        key: 'credit',
        textAlign: 'right',
      },
    ]

    if (!this.props.params.cashFlowDetail) {
      columns.push({
        title: intl.formatMessage({ id: 'report.running_balance' }),
        dataIndex: 'running_balance',
        key: 'running_balance',
        textAlign: 'right',
      })
    }

    const header = columns.map((item) => {
      return (
        <th key={item.key} style={{ textAlign: item.textAlign === 'right' ? 'right' : 'left' }}>
          <span className="ant-table-header-column">
            <div>
              <span className="ant-table-column-title">{item.title}</span>
              <span className="ant-table-column-sorter" />
            </div>
          </span>
        </th>
      )
    })

    const body = this.getBody()

    const pageHeader = this.getHeader()
    return (
      <>
        <PageHeader
          title={pageHeader}
          extra={[
            <ReactToPrint
              key={1}
              trigger={() => <Print />}
              content={() => this.print}
              removeAfterPrint
            />,
          ]}
        />
        <Filter
          setParams={this.setParams}
          isMobileView={isMobileView}
          params={params}
          onSearch={this.searchHandler}
          initialParams={initialParams}
          showClearBtn={this.clearFilter().showClearBtn}
          onClear={this.clearFilter().onClear}
          onToggleGraph={this.toggleGraphHandler}
          showGraph={showGraph}
          showSearch={!this.props.params.cashFlowDetail}
        />
        {showGraph ? <Charts params={this.getParams()} /> : null}
        {this.state.summary ? (
          this.renderLoading()
        ) : (
          <React.Fragment>
            <div className="no-margin-h" style={{ overflow: 'auto' }}>
              <div className="ant-table-wrapper" style={{ marginTop: 20 }}>
                <div className="ant-spin-nested-loading">
                  {loading && (
                    <div>
                      <div className="ant-spin ant-spin-spinning ant-table-without-pagination ant-table-spin-holder">
                        <span className="ant-spin-dot ant-spin-dot-spin">
                          <i className="ant-spin-dot-item" />
                          <i className="ant-spin-dot-item" />
                          <i className="ant-spin-dot-item" />
                          <i className="ant-spin-dot-item" />
                        </span>
                      </div>
                    </div>
                  )}
                  <Spin spinning={loading}>
                    <div className="ant-table-custom ant-table-default ant-table-empty ant-table-scroll-position-left">
                      <div className="ant-table-content">
                        <div className="ant-table-body">
                          <table>
                            <thead className="ant-table-thead-custom">
                              <tr>{header}</tr>
                            </thead>
                            <tbody className="ant-table-tbody-custom">{body}</tbody>
                          </table>
                        </div>
                        {!body.length && (
                          <div className="ant-table-placeholder">
                            <div className="ant-empty ant-empty-normal">
                              <div className="ant-empty-image">
                                <svg
                                  width="64"
                                  height="41"
                                  viewBox="0 0 64 41"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                    <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7" />
                                    <g fillRule="nonzero" stroke="#D9D9D9">
                                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                      <path
                                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                        fill="#FAFAFA"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p className="ant-empty-description">
                                {intl.formatMessage({ id: 'report.no_data' })}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
            {total > per_page && (
              <div style={{ textAlign: 'right' }} className="mt-3">
                <Pagination
                  pageSize={parseInt(per_page, 10) || 0}
                  current={page}
                  onChange={(_page, _per_page) =>
                    this.setState({ page: _page, per_page: _per_page }, this.loadData)
                  }
                  total={total || 0}
                  showSizeChanger
                />
              </div>
            )}
          </React.Fragment>
        )}
        {/* not use display: none to prevent this error */}
        {/* Uncaught DOMException: Failed to execute 'drawImage' on 'CanvasRenderingContext2D': The image argument is a canvas element with a width or height of 0. */}
        <div style={{ overflow: 'hidden', height: 0 }}>
          <PrintTable
            ref={(el) => {
              this.print = el
            }}
            data={data}
            columns={columns}
            header={pageHeader}
            journalTotal={journal_total}
            params={this.props.params}
            getParams={this.getParams}
            showGraph={showGraph}
            formatNumber={formatNumber}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isMobileView: state.settings.isMobileView,
    loadedInit: state.inits.loadedInit,
    show: state.finance.accountTransactionReport.show,
  }
}

export default connect(mapStateToProps)(injectIntl(AccountTransactionsReports))
