import React, { memo, useCallback, useState } from 'react'
import { Dropdown, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import News from 'containers/Notification/News'
import { useResponsive } from 'utils/hooks'
import { useSelector } from 'react-redux'
import { darkModeSelector } from 'redux/selectors'
import { compareWithJSON } from 'utils/helper'
import styles from './style.module.scss'

const Actions = () => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)
  const darkMode = useSelector(darkModeSelector)

  const { isMobileView } = useResponsive()

  const visibleChangeHandler = (flag) => setVisible(flag)

  const stylePane = { height: 400, overflow: 'auto' }
  if (!isMobileView) {
    stylePane.width = 400
  }

  const dropdownRender = useCallback(() => {
    return (
      <div
        className={`card cui__utils__shadow width-350 overflow-hidden border-0 ${
          darkMode ? 'bg-dark-3 text-light' : 'bg-light'
        }`}
      >
        <div className="card-body" style={stylePane}>
          <News />
        </div>
      </div>
    )
  }, [darkMode, stylePane])
  return (
    <Tooltip
      open={visible ? false : undefined}
      title={formatMessage({ id: 'topBar.latestKledoUpdate' })}
    >
      <div>
        <Dropdown
          dropdownRender={dropdownRender}
          trigger={['click']}
          placement="bottomRight"
          open={visible}
          arrow
          onOpenChange={visibleChangeHandler}
        >
          <div className={styles.dropdown}>
            <FontAwesomeIcon icon="bell" className={`${styles.icon}`} />
          </div>
        </Dropdown>
      </div>
    </Tooltip>
  )
}

export default memo(Actions, compareWithJSON)
