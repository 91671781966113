import axios from 'axios'
import { stringify } from 'query-string'
import _ from 'lodash'

import { cleanBlankValue } from 'utils/helper'

export const financeReportingsGraphs = (payload) => {
  const query = stringify(payload)
  return axios.get(`/finance/reportings/graphs?${query}`)
}

export const attachmentsReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/attachments?${query}`)
}

export const exportsReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/exports?${query}`)
}

export const importsReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/imports?${query}`)
}

export const importsDetailReports = (id) => {
  return axios.get(`/reportings/imports/${id}`)
}

export const importsTransactionsReports = ({ payload, id }) => {
  const query = stringify(payload)
  return axios.get(`/reportings/imports/${id}/transactions?${query}`)
}

export const deleteImportsReports = (id) => axios.delete(`/reportings/imports/${id}`)

export const salesPerPersonReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerPerson?${query}`)
}

export const getSalesPerPersonReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerPerson/whatsapp?${query}`)
}

export const getSalesPerPersonReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerPerson/email?${query}`)
}

export const sendSalesPerPersonReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/salesPerPerson/email?${query}`, data)
}

export const salesPerPersonDailyReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerPersonDaily?${query}`)
}

export const exportSalesPerPersonReports = (params) => {
  const query = _.cloneDeep(params)
  query.export = params.type
  query.type = null

  return axios.get('/reportings/salesPerPerson', {
    responseType: 'arraybuffer',
    params: cleanBlankValue(query),
  })
}

export const shippingCostPerExpeditionReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/shippingCostPerExpedition?${query}`)
}

export const getShippingCostPerExpeditionReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/shippingCostPerExpedition/whatsapp?${query}`)
}

export const getShippingCostPerExpeditionReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/shippingCostPerExpedition/email?${query}`)
}

export const sendShippingCostPerExpeditionReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/shippingCostPerExpedition/email?${query}`, data)
}

export const shippingCostPerExpeditionDailyReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/shippingCostPerExpeditionDaily?${query}`)
}

export const exportShippingCostPerExpeditionReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/shippingCostPerExpedition?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const inventorySummaryReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventorySummary?${query}`)
}

export const exportInventorySmmaryReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/inventorySummary?${query}`, { responseType: 'arraybuffer' })
}

export const inventorySummaryFifoReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventorySummaryFifo?${query}`)
}

export const exportInventorySummaryFifoReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/inventorySummaryFifo?${query}`, { responseType: 'arraybuffer' })
}

export const inventorySummaryDetailFifoReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventorySummaryDetailFifo?${query}`)
}

export const inventoryStockMovementReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockMovement?${query}`)
}

export const grandTotalInventoryStockMovementReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockMovement/grandTotal?${query}`)
}

export const exportInventoryStockMovementReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/inventoryStockMovement?${query}`, { responseType: 'arraybuffer' })
}

export const inventoryStockMovementDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockMovementDetail?${query}`)
}

export const exportInventoryStockMovementDetailReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/inventoryStockMovementDetail?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const inventoryStockMovementFifoReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockMovementFifo?${query}`)
}

export const exportInventoryStockMovementFifoReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/inventoryStockMovementFifo?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const inventoryStockMovementDetailFifoReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockMovementDetailFifo?${query}`)
}

export const exportInventoryStockMovementDetailFifoReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/inventoryStockMovementDetailFifo?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const inventoryStockAdjustmentReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockAdjustment?${query}`)
}

export const inventoryStockAdjustmentReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockAdjustment/whatsapp?${query}`)
}

export const inventoryStockAdjustmentReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockAdjustment/email?${query}`)
}

export const sendInventoryStockAdjustmentReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/inventoryStockAdjustment/email?${query}`, data)
}

export const exportInventoryStockAdjustmentReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockAdjustment?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const inventoryStockAdjustmentDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryStockAdjustmentDetail?${query}`)
}

export const inventoryWarehouseTransferReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryWarehouseTransfer?${query}`)
}

export const inventoryWarehouseTransferReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryWarehouseTransfer/whatsapp?${query}`)
}

export const inventoryWarehouseTransferReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryWarehouseTransfer/email?${query}`)
}

export const sendInventoryWarehouseTransferReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/inventoryWarehouseTransfer/email?${query}`, data)
}

export const exportInventoryWarehouseTransferReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryWarehouseTransfer?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const inventoryWarehouseTransferDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryWarehouseTransfer/detail?${query}`)
}

export const warehouseStockSummaryReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/warehouseStock?${query}`)
}

export const exportInventoryStockSummaryReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/warehouseStock?${query}`, { responseType: 'arraybuffer' })
}

export const productionReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/production?${query}`)
}

export const getProductionReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/production/whatsapp?${query}`)
}

export const getProductionReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/production/email?${query}`)
}

export const sendProductionReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/production/email?${query}`, data)
}

export const exportProductionReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/production?${query}`, { responseType: 'arraybuffer' })
}

export const guestReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/guest?${query}`)
}

export const occupancyReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/occupancy?${query}`)
}

export const bookingReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/booking?${query}`)
}

export const revenueReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/revenue?${query}`)
}

export const closureReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/closure?${query}`)
}

export const channelReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/channel?${query}`)
}

export const posReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/pos?${query}`)
}

export const posSalesReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/pos/sales?${query}`)
}

export const generalLedgerReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/generalLedger?${query}`)
}

export const generalLedgerAccountsReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/generalLedgerAccounts?${query}`)
}

export const generalLedgerAccountsDetailReports = (accountId, payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/generalLedgerAccounts/${accountId}/detail?${query}`)
}

export const generalLedgerStatsReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/generalLedger/stats?${query}`)
}

export const getGeneralLedgerReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/generalLedger/whatsapp?${query}`)
}

export const getGeneralLedgerReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/generalLedger/email?${query}`)
}

export const sendGeneralLedgerReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/generalLedger/email?${query}`, data)
}

export const generalLedgerCategoryReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/generalLedger/categories?${query}`)
}

export const journalReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/journal?${query}`)
}

export const exportJournalReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/journal?${query}`, { responseType: 'arraybuffer' })
}

export const getJournalReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/journal/whatsapp?${query}`)
}

export const getJournalReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/journal/email?${query}`)
}

export const sendJournalReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/journal/email?${query}`, data)
}

export const bankSummaryReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/bankSummary?${query}`)
}

export const getBankSummaryReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/bankSummary/whatsapp?${query}`)
}

export const getBankSummaryReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/bankSummary/email?${query}`)
}

export const sendBankSummaryReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/bankSummary/email?${query}`, data)
}

export const exportBankSummaryReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/bankSummary?${query}`, { responseType: 'arraybuffer' })
}

export const salesTaxReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesTax?${query}`)
}

export const exportSalesTaxReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/salesTax?${query}`, { responseType: 'arraybuffer' })
}

export const salesTaxDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesTaxDetail?${query}`)
}

export const customerInvoiceReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/customerInvoice?${query}`)
}

export const customerInvoiceDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/customerInvoiceDetail?${query}`)
}

export const getCustomerInvoiceReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/customerInvoice/whatsapp?${query}`)
}

export const getCustomerInvoiceReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/customerInvoice/email?${query}`)
}

export const sendCustomerInvoiceReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/customerInvoice/email?${query}`, data)
}

export const exportCustomerInvoiceReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/customerInvoice?${query}`, { responseType: 'arraybuffer' })
}

export const profitabilityPerInvoiceReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/invoiceProfitability?${query}`)
}

export const profitabilityPerInvoiceGrandTotalReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/invoiceProfitability/grandTotal?${query}`)
}

export const getProfitabilityPerInvoiceReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/invoiceProfitability/whatsapp?${query}`)
}

export const getProfitabilityPerInvoiceReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/invoiceProfitability/email?${query}`)
}

export const sendProfitabilityPerInvoiceReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/invoiceProfitability/email?${query}`, data)
}

export const exportProfitabilityPerInvoiceReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/invoiceProfitability?${query}`, { responseType: 'arraybuffer' })
}

export const incomePerCustomerReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/incomePerCustomer?${query}`)
}

export const incomePerCustomerDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/incomePerCustomerDetail?${query}`)
}

export const getIncomePerCustomerReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/incomePerCustomer/whatsapp?${query}`)
}

export const getIncomePerCustomerReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/incomePerCustomer/email?${query}`)
}

export const sendIncomePerCustomerReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/incomePerCustomer/email?${query}`, data)
}

export const exportIncomePerCustomerReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/incomePerCustomer?${query}`, { responseType: 'arraybuffer' })
}

export const expensePerContactReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/expensePerContact?${query}`)
}

export const exportExpensePerContactReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/expensePerContact?${query}`, { responseType: 'arraybuffer' })
}

export const salesPerProductReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerProduct?${query}`)
}

export const getSalesPerProductReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerProduct/whatsapp?${query}`)
}

export const getSalesPerProductReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerProduct/email?${query}`)
}

export const sendSalesPerProductReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/salesPerProduct/email?${query}`, data)
}

export const exportSalesPerProductReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/salesPerProduct?${query}`, { responseType: 'arraybuffer' })
}

export const orderPerProductReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/orderPerProduct?${query}`)
}

export const getOrderPerProductReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/orderPerProduct/whatsapp?${query}`)
}

export const getOrderPerProductReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/orderPerProduct/email?${query}`)
}

export const sendOrderPerProductReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/orderPerProduct/email?${query}`, data)
}

export const exportOrderPerProductReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/orderPerProduct?${query}`, { responseType: 'arraybuffer' })
}

export const getSalesPerProductCategory = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerProductCategory?${query}`)
}

export const exportSalesPerProductCategory = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/salesPerProductCategory?${query}`, {
    responseType: 'arraybuffer',
  })
}
export const exportSalesPerProductCategoryWhatsapp = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/salesPerProductCategory/whatsapp?${query}`)
}
export const exportSalesPerProductCategoryEmail = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/salesPerProductCategory/email?${query}`)
}
export const postSalesPerProductCategoryEmail = (queryPayload, payload) => {
  const query = stringify(queryPayload)
  return axios.post(`/reportings/salesPerProductCategory/email?${query}`, payload)
}

export const purchasesPerVendorReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasesPerVendor?${query}`)
}

export const purchasesPerVendorDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasesPerVendorDetail?${query}`)
}

export const getPurchasePerVendorReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasePerVendor/whatsapp?${query}`)
}

export const getPurchasePerVendorReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasePerVendor/email?${query}`)
}

export const sendPurchasePerVendorReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/purchasePerVendor/email?${query}`, data)
}

export const exportPurchasesPerVendorReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/purchasesPerVendor?${query}`, { responseType: 'arraybuffer' })
}

export const purchasesPerProductReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasesPerProduct?${query}`)
}

export const purchasesPerProductDetailReports = ({ id, ...payload }) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasesPerProduct/${id}?${query}`)
}

export const getPurchasePerProductReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasePerProduct/whatsapp?${query}`)
}

export const getPurchasePerProductReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasePerProduct/email?${query}`)
}

export const sendPurchasePerProductReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/purchasePerProduct/email?${query}`, data)
}

export const exportPurchasesPerProductReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/purchasesPerProduct?${query}`, { responseType: 'arraybuffer' })
}

export const supplierInvoiceReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/supplierInvoice?${query}`)
}

export const getSupplierInvoiceReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/supplierInvoice/whatsapp?${query}`)
}

export const getSupplierInvoiceReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/supplierInvoice/email?${query}`)
}

export const sendSupplierInvoiceReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/supplierInvoice/email?${query}`, data)
}

export const exportSupplierInvoiceReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/supplierInvoice?${query}`, { responseType: 'arraybuffer' })
}

export const agedReceivableReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/agedReceivable?${query}`)
}

export const getAgedReceivableReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/agedReceivable/whatsapp?${query}`)
}

export const getAgedReceivableReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/agedReceivable/email?${query}`)
}

export const sendAgedReceivableReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/agedReceivable/email?${query}`, data)
}

export const exportAgedReceivableReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/agedReceivable?${query}`, { responseType: 'arraybuffer' })
}

export const agedReceivableDetailReports = ({ id, payload }) => {
  const query = stringify(payload)
  return axios.get(`/reportings/agedReceivableDetail/${id}?${query}`)
}

export const exportAgedReceivableDetailReports = ({ id, params }) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/agedReceivableDetail/${id}?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const salesPerPeriodReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerPeriod?${query}`)
}

export const getSalesPerPeriodReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerPeriod/whatsapp?${query}`)
}

export const getSalesPerPeriodReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesPerPeriod/email?${query}`)
}

export const sendSalesPerPeriodReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/salesPerPeriod/email?${query}`, data)
}

export const exportSalesPerPeriodReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/salesPerPeriod?${query}`, { responseType: 'arraybuffer' })
}

export const purchasesPerPeriodReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasesPerPeriod?${query}`)
}

export const getPurchasesPerPeriodReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasesPerPeriod/whatsapp?${query}`)
}

export const getPurchasesPerPeriodReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchasesPerPeriod/email?${query}`)
}

export const sendPurchasesPerPeriodReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/purchasesPerPeriod/email?${query}`, data)
}

export const exportPurchasesPerPeriodReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/purchasesPerPeriod?${query}`, { responseType: 'arraybuffer' })
}

export const agedPayableReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/agedPayable?${query}`)
}

export const getAgedPayableReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/agedPayable/whatsapp?${query}`)
}

export const getAgedPayableReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/agedPayable/email?${query}`)
}

export const sendAgedPayableReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/agedPayable/email?${query}`, data)
}

export const exportAgedPayableReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/agedPayable?${query}`, { responseType: 'arraybuffer' })
}

export const agedPayableDetailReports = ({ id, payload }) => {
  const query = stringify(payload)
  return axios.get(`/reportings/agedPayableDetail/${id}?${query}`)
}

export const exportAgedPayableDetailReports = ({ id, params }) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/agedPayableDetail/${id}?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const receivablePayableReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/receivablePayable?${query}`)
}

export const exportReceivablePayableReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/receivablePayable?${query}`, { responseType: 'arraybuffer' })
}

export const getReceivablePayableReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/receivablePayable/whatsapp?${query}`)
}

export const getReceivablePayableReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/receivablePayable/email?${query}`)
}

export const sendReceivablePayableReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/receivablePayable/email?${query}`, data)
}

export const expenseClaimReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/expenseClaim?${query}`)
}

export const exportExpenseClaimReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/expenseClaim?${query}`, { responseType: 'arraybuffer' })
}

export const expenseClaimDetailReports = (id, payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/expenseClaimDetail/${id}?${query}`)
}

export const trialBalanceReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/trialBalance?${query}`)
}

export const exportTrialBalanceReports = (payload) => {
  const query = stringify(cleanBlankValue(payload))
  return axios.get(`/reportings/trialBalance?${query}`, { responseType: 'arraybuffer' })
}

export const getTrialBalanceReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/trialBalance/whatsapp?${query}`)
}

export const getTrialBalanceReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/trialBalance/email?${query}`)
}

export const sendTrialBalanceReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/trialBalance/email?${query}`, data)
}

export const trialBalanceDetailReports = (id, payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/trialBalanceDetail/${id}?${query}`)
}

export const profitLossReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/profitLoss?${query}`)
}

export const getProfitLossReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/profitLoss/whatsapp?${query}`)
}

export const getProfitLossReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/profitLoss/email?${query}`)
}

export const sendProfitLossReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/profitLoss/email?${query}`, data)
}

export const exportProfitLossReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/profitLoss?${query}`, { responseType: 'arraybuffer' })
}

export const consolidationProfitLossReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/consolidationProfitLoss?${query}`)
}

export const exportConsolidationProfitLossReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/consolidationProfitLoss?${query}`, { responseType: 'arraybuffer' })
}

export const cashFlowReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/cashFlow?${query}`)
}

export const getCashFlowReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/cashFlow/whatsapp?${query}`)
}

export const getCashFlowReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/cashFlow/email?${query}`)
}

export const sendCashFlowReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/cashFlow/email?${query}`, data)
}

export const exportCashFlowReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/cashFlow?${query}`, { responseType: 'arraybuffer' })
}

export const cashFlowDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/cashFlowDetail?${query}`)
}

export const consolidationCashFlowReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/consolidationCashFlow?${query}`)
}

export const exportConsolidationCashFlowReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/consolidationCashFlow?${query}`, { responseType: 'arraybuffer' })
}

export const balanceSheetReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/balanceSheet?${query}`)
}

export const getBalanceSheetReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/balanceSheet/whatsapp?${query}`)
}

export const getBalanceSheetReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/balanceSheet/email?${query}`)
}

export const sendBalanceSheetReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/balanceSheet/email?${query}`, data)
}

export const exportBalanceSheetReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/balanceSheet?${query}`, { responseType: 'arraybuffer' })
}

export const consolidationBalanceSheetReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/consolidationBalanceSheet?${query}`)
}

export const exportConsolidationBalanceSheetReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/consolidationBalanceSheet?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const consolidationWorksheetReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/consolidationWorksheet?${query}`)
}

export const exportConsolidationWorksheetReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/consolidationWorksheet?${query}`, { responseType: 'arraybuffer' })
}

export const equityMovementReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/equityMovement?${query}`)
}

export const getEquityMovementReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/equityMovement/whatsapp?${query}`)
}

export const getEquityMovementReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/equityMovement/email?${query}`)
}

export const sendEquityMovementReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/equityMovement/email?${query}`, data)
}

export const exportEquityMovementReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/equityMovement?${query}`, { responseType: 'arraybuffer' })
}

export const executiveSummaryReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/executiveSummary?${query}`)
}

export const getExecutiveSummaryReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/executiveSummary/whatsapp?${query}`)
}

export const getExecutiveSummaryReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/executiveSummary/email?${query}`)
}

export const sendExecutiveSummaryReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/executiveSummary/email?${query}`, data)
}

export const exportExecutiveSummaryReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/executiveSummary?${query}`, { responseType: 'arraybuffer' })
}

export const fixedAssetDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/fixedAssetDetail?${query}`)
}

export const exportFixedAssetDetailReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/fixedAssetDetail?${query}`, { responseType: 'arraybuffer' })
}

export const fixedAssetReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/fixedAsset?${query}`)
}

export const exportFixedAssetReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/fixedAsset?${query}`, { responseType: 'arraybuffer' })
}

export const fixedAssetReleaseReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/fixedAsset/releaseAsset?${query}`)
}

export const exportFixedAssetReleaseReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/fixedAsset/releaseAsset?${query}`, { responseType: 'arraybuffer' })
}

export const witholdingTaxReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/witholdingTax?${query}`)
}

export const exportWitholdingTaxReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/witholdingTax?${query}`, { responseType: 'arraybuffer' })
}

export const salesDeliveryReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesDelivery?${query}`)
}

export const getSalesDeliveryReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesDelivery/whatsapp?${query}`)
}

export const getSalesDeliveryReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesDelivery/email?${query}`)
}

export const sendSalesDeliveryReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/salesDelivery/email?${query}`, data)
}

export const exportSalesDeliveryReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/salesDelivery?${query}`, { responseType: 'arraybuffer' })
}

export const purchaseDeliveryReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseDelivery?${query}`)
}

export const getPurchaseDeliveryReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseDelivery/whatsapp?${query}`)
}

export const getPurchaseDeliveryReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseDelivery/email?${query}`)
}

export const sendPurchaseDeliveryReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/purchaseDelivery/email?${query}`, data)
}

export const exportPurchaseDeliveryReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/purchaseDelivery?${query}`, { responseType: 'arraybuffer' })
}

export const addBudgetReports = (data) => {
  return axios.post(`/reportings/budgets`, data)
}

export const budgetReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/budgets?${query}`)
}

export const exportBudgetsReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/budgets?${query}`, { responseType: 'arraybuffer' })
}

export const budgetDetailReports = (id, payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/budgets/${id}?${query}`)
}

export const editBudgetReports = (id, data) => {
  return axios.put(`/reportings/budgets/${id}`, data)
}

export const deleteBudgetReports = (id) => {
  return axios.delete(`/reportings/budgets/${id}`)
}

export const budgetProfitLossReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/budget/profitLoss?${query}`)
}

export const exportBudgetProfitLossReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/budget/profitLoss?${query}`, { responseType: 'arraybuffer' })
}

export const budgetProfitLossDetailReports = (id, payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/budget/profitLoss/${id}?${query}`)
}

export const salesDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesDetail?${query}`)
}

export const getSalesDetailReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesDetail/whatsapp?${query}`)
}

export const getSalesDetailReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/salesDetail/email?${query}`)
}

export const sendSalesDetailReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/salesDetail/email?${query}`, data)
}

export const exportSalesDetailReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/salesDetail?${query}`, { responseType: 'arraybuffer' })
}

export const purchaseDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseDetail?${query}`)
}

export const getPurchaseDetailReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseDetail/whatsapp?${query}`)
}

export const getPurchaseDetailReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseDetail/email?${query}`)
}

export const sendPurchaseDetailReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/purchaseDetail/email?${query}`, data)
}

export const exportPurchaseDetailReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/purchaseDetail?${query}`, { responseType: 'arraybuffer' })
}

export const productProfitabilityReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/productProfitability?${query}`)
}

export const exportProductProfitabilityReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/productProfitability?${query}`, { responseType: 'arraybuffer' })
}

export const invoicePaymentReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/invoicesPayment?${query}`)
}

export const exportInvoicePaymentReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/invoicesPayment?${query}`, { responseType: 'arraybuffer' })
}

export const getInvoicePaymentReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/invoicesPayment/whatsapp?${query}`)
}

export const getInvoicePaymentReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/invoicesPayment/email?${query}`)
}

export const sendInvoicePaymentReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/invoicesPayment/email?${query}`, data)
}

export const purchaseInvoicePaymentReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseInvoicesPayment?${query}`)
}

export const exportPurchaseInvoicePaymentReports = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/reportings/purchaseInvoicesPayment?${query}`, { responseType: 'arraybuffer' })
}

export const getPurchaseInvoicePaymentReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseInvoicesPayment/whatsapp?${query}`)
}

export const getPurchaseInvoicePaymentReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseInvoicesPayment/email?${query}`)
}

export const sendPurchaseInvoicePaymentReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/purchaseInvoicesPayment/email?${query}`, data)
}

export const exportProductSalesContacts = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productSalesContacts?${query}`, {
    responseType: 'arraybuffer',
  })
}
export const getProductSalesContacts = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productSalesContacts?${query}`)
}
export const getProductSalesContactsDetails = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productSalesContactsDetail?${query}`)
}

export const getProductSalesContactWATemplate = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productSalesContacts/whatsapp?${query}`)
}
export const getProductSalesContactEmailTemplate = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productSalesContacts/email?${query}`)
}
export const postProductSalesContactEmailReport = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.post(`/reportings/productSalesContacts/email?${query}`)
}

export const getProductPurchasesContactsDetails = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productPurchasesContactsDetail?${query}`)
}
export const getProductPurchasesContacts = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productPurchasesContacts?${query}`)
}
export const exportProductPurchasesContacts = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productPurchasesContacts?${query}`, {
    responseType: 'arraybuffer',
  })
}

export const getProductPurchasesContactWATemplate = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productPurchasesContacts/whatsapp?${query}`)
}

export const getProductPurchasesContactEmailTemplate = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/productPurchasesContacts/email?${query}`)
}
export const postProductPurchasesContactEmailReport = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.post(`/reportings/productPurchasesContacts/email?${query}`)
}

export const inventoryProductSerialStockMovementDetailReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryProductSerialStockMovementDetail?${query}`)
}

export const inventoryProductSerialStockReports = (params) => {
  const query = stringify(cleanBlankValue(params))
  return axios.get(`/reportings/inventoryProductSerialStock?${query}`)
}

export const inventoryProductSerialStockDetailReports = (params) => {
  const query = stringify(cleanBlankValue(params))
  return axios.get(`/reportings/inventoryProductSerialStockDetail?${query}`)
}

export const inventoryProductSerialExpiresSoonReports = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/inventoryProductSerialExpiresSoon?${query}`)
}

export const inventoryProductSerialExpiryAgeReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryProductSerialExpiryAge?${query}`)
}

export const inventoryProductSerialHistoryReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/inventoryProductSerialHistory?${query}`)
}

export const dutyStampsReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/stamps?${query}`)
}

export const getActivityTeamReports = (queryPayload) => {
  const query = stringify(queryPayload)
  return axios.get(`/reportings/activity-team?${query}`)
}

export const purchaseOrderPerProductReports = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseOrderPerProduct?${query}`)
}

export const getPurchaseOrderPerProductReportsWaTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseOrderPerProduct/whatsapp?${query}`)
}

export const getPurchaseOrderPerProductReportsEmailTemplate = (payload) => {
  const query = stringify(payload)
  return axios.get(`/reportings/purchaseOrderPerProduct/email?${query}`)
}

export const sendPurchaseOrderPerProductReportsEmail = (payload, data) => {
  const query = stringify(payload)
  return axios.post(`/reportings/purchaseOrderPerProduct/email?${query}`, data)
}

export const exportPurchaseOrderPerProductReports = (params) => {
  const query = stringify(cleanBlankValue(params))
  return axios.get(`/reportings/purchaseOrderPerProduct?${query}`, { responseType: 'arraybuffer' })
}
