import React, { useState } from 'react'
import { Button, Form, Input, message, Modal, Select, Skeleton } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import { openNewTab } from 'utils/helper'
import { Cancel, Send } from 'components/UI'
import HideFeature from 'containers/Billing/HideFeature'

const { TextArea } = Input

const SendWaModal = ({
  intl,
  sendTitle,
  getWaTemplate,
  show = false,
  data = { contact: {} },
  onCloseWaModal,
  goToWaTamplate,
}) => {
  const formRef = React.createRef()

  const [loadingWaTemplate, setLoadingWaTemplate] = React.useState(false)
  const [waTemplate, setWaTemplate] = React.useState({ body: '', phone_number: '' })
  const [phonesNumber, setPhonesNumber] = useState([])

  React.useEffect(() => {
    const getTemplate = async () => {
      try {
        setLoadingWaTemplate(true)
        const response = await getWaTemplate()
        setLoadingWaTemplate(false)
        const currentData = response.data.data
        if (currentData.phones) {
          setPhonesNumber(currentData.phones.filter(Boolean))
          setWaTemplate({
            ...currentData,
            phone_number: currentData.phones[0],
          })
        } else {
          setWaTemplate({ ...currentData, phone_number: currentData.phone })
        }
      } catch (e) {
        console.log(e)
        message.error(intl.formatMessage({ id: 'sms.cannot_send_wa' }))
      }
    }

    if (show) {
      getTemplate()
    }
  }, [show, getWaTemplate, intl])

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue({
        phone_number: waTemplate.phone_number,
        body: waTemplate.body,
      })
    }
  }, [data, waTemplate, formRef])

  const onSendWa = async (values) => {
    // use replace with a g flag on regex instead replaceAll to prevent a user cannot send whatsapp
    // on a Chrome version under 85
    // source: https://caniuse.com/?search=replaceAll
    const body = values.body
      .replace(/(?:\r\n|\r|\n)/g, '%0a')
      .replace(/#/g, encodeURIComponent('#'))
      .replace(/&/g, encodeURIComponent('&'))

    let phone = values.phone_number
    if (phone.charAt(0) === '+') {
      phone = phone.substring(1)
    }
    if (phone.charAt(0) === '0') {
      phone = phone.substring(1)
      phone = `62${phone}`
    }
    openNewTab(`https://api.whatsapp.com/send?phone=${phone}&text=${body}`, false)
    onCloseWaModal()
  }

  return (
    <Modal
      title={
        sendTitle === 'button.send_wa' || !sendTitle
          ? intl.formatMessage({ id: 'button.send_wa' })
          : sendTitle
      }
      open={show}
      onCancel={onCloseWaModal}
      destroyOnClose
      footer={[
        <React.Fragment key="template">
          {goToWaTamplate && (
            <Button style={{ float: 'left' }} icon={<EditOutlined />} onClick={goToWaTamplate}>
              {intl.formatMessage({ id: 'wa.change_template' })}
            </Button>
          )}
        </React.Fragment>,
        <Cancel key={`_${0}`} onClick={onCloseWaModal} />,
        <Send form="sendWaForm" key="submit" htmlType="submit" />,
      ]}
    >
      <HideFeature
        code="whatsapp"
        feature={
          sendTitle === 'button.send_wa' || !sendTitle
            ? intl.formatMessage({ id: 'button.send_wa' })
            : sendTitle
        }
      >
        {loadingWaTemplate ? (
          <Skeleton active />
        ) : (
          <Form ref={formRef} onFinish={onSendWa} layout="vertical" id="sendWaForm">
            <Form.Item
              label={intl.formatMessage({ id: 'sms.destination_number' })}
              name="phone_number"
              rules={[
                { required: true, message: intl.formatMessage({ id: 'sms.please_input_phone' }) },
                {
                  min: 6,
                  message: intl.formatMessage(
                    { id: 'financeContacts.min_characters' },
                    { value: 6 },
                  ),
                },
                {
                  pattern: /^[\d\+]+$/,
                  message: intl.formatMessage({ id: 'financeContacts.only_number_allowed' }),
                },
              ]}
            >
              {phonesNumber.length < 2 ? (
                <Input placeholder={intl.formatMessage({ id: 'sms.destination_number' })} />
              ) : (
                <Select
                  placeholder="Select a option and change input text above"
                  options={phonesNumber.map((phoneNumber) => ({
                    label: phoneNumber,
                    value: phoneNumber,
                  }))}
                />
              )}
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'sms.wa_content' })}
              name="body"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'sms.please_input_wa_content' }),
                },
                {
                  min: 20,
                  message: intl.formatMessage(
                    { id: 'financeContacts.min_characters' },
                    { value: 20 },
                  ),
                },
              ]}
            >
              <TextArea
                placeholder={intl.formatMessage({ id: 'sms.wa_content' })}
                autoSize={{ minRows: 2, maxRows: 8 }}
              />
            </Form.Item>
          </Form>
        )}
      </HideFeature>
    </Modal>
  )
}

export default SendWaModal
