import React, { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { translationSelector } from 'redux/selectors'
import { BarChartOutlined } from '@ant-design/icons'
import { compareWithJSON } from 'utils/helper'
import useNavigation from 'utils/hooks/useNavigation'
import DropdownClickButton from '../DropdownClickButton'

const ReportDropdown = ({ options, ...props }) => {
  const { formatMessage } = useIntl()
  const { reportmenu } = useSelector(translationSelector)

  const { onNavigate } = useNavigation()

  const items = useMemo(() => {
    if (!Array.isArray(options)) {
      return []
    }
    const menuItems = [
      {
        key: 'report_balance_sheet',
        label: reportmenu.balance_sheet,
        onClick: () => onNavigate('/reports/balance-sheet'),
        permission: 'report_balance_sheet',
      },
      {
        key: 'report_cash_flow',
        label: reportmenu.cash_flow,
        onClick: () => onNavigate('/reports/cash-flow'),
        permission: 'report_cash_flow',
      },
      {
        key: 'report_profit_and_loss',
        label: reportmenu.profit_and_loss,
        onClick: () => onNavigate('/reports/profit-loss'),
        permission: 'report_profit_loss',
      },
      {
        key: 'report_equity_movement',
        label: reportmenu.equity_movement,
        onClick: () => onNavigate('/reports/equity-movement'),
        permission: 'report_equity_movement',
      },
      {
        key: 'report_executive_summary',
        label: reportmenu.executive_summary,
        onClick: () => onNavigate('/reports/executive-summary'),
        permission: 'report_executive_summary',
      },
      {
        key: 'report_receivable_payable',
        label: reportmenu.payable_credit_per_contact,
        onClick: () => onNavigate('/reports/receivable-payable-per-contact'),
        permission: 'report_receivable_payable',
      },
      {
        key: 'report_bank_summary',
        label: reportmenu.bank_summary,
        onClick: () => onNavigate('/reports/bank-summary'),
        permission: 'report_bank_summary',
      },
      {
        key: 'report_general_ledger',
        label: reportmenu.general_ledger,
        onClick: () => onNavigate('/reports/general-ledger'),
        permission: 'report_general_ledger',
      },
      {
        key: 'report_journal',
        label: reportmenu.journal,
        onClick: () => onNavigate('/reports/journal'),
        permission: 'report_journal',
      },
      {
        key: 'report_trial_balance',
        label: reportmenu.trial_balance,
        onClick: () => onNavigate('/reports/trial-balance'),
        permission: 'report_trial_balance',
      },
      {
        key: 'report_sales_detail',
        label: reportmenu.sales_detail,
        onClick: () => onNavigate('/reports/sales-detail'),
        permission: 'report_sales_detail',
      },
      {
        key: 'report_aged_receivables',
        label: reportmenu.aged_receivables,
        onClick: () => onNavigate('/reports/aged-receivable'),
        permission: 'report_aged_receivables',
      },
      {
        key: 'report_customer_invoice',
        label: reportmenu.customer_invoice,
        onClick: () => onNavigate('/reports/customer-invoice'),
        permission: 'report_customer_invoice',
      },
      {
        key: 'report_profitability_product',
        label: reportmenu.profitability_product,
        onClick: () => onNavigate('/reports/product-profitability'),
        permission: 'report_product_profitability',
      },
      {
        key: 'report_profitability_per_invoice',
        label: reportmenu.profitability_per_invoice,
        onClick: () => onNavigate('/reports/profitability-per-invoice'),
        permission: 'report_profitability_per_invoice',
      },
      {
        key: 'report_income_per_customer',
        label: reportmenu.income_per_customer,
        onClick: () => onNavigate('/reports/income-per-customer'),
        permission: 'report_income_per_customer',
      },
      {
        key: 'report_sales_per_product',
        label: reportmenu.sales_per_product,
        onClick: () => onNavigate('/reports/sales-per-product'),
        permission: 'report_sales_per_product',
      },
      {
        key: 'report_order_per_product',
        label: reportmenu.order_per_product,
        onClick: () => onNavigate('/reports/order-per-product'),
        permission: 'report_order_per_product',
      },
      {
        key: 'report_sales_per_sales_person',
        label: reportmenu.sales_per_sales_person,
        onClick: () => onNavigate('/reports/sales-per-sales-person'),
        permission: 'report_sales_per_sales_person',
      },
      {
        key: 'report_sales_delivery',
        label: reportmenu.sales_delivery,
        onClick: () => onNavigate('/reports/sales-delivery'),
        permission: 'report_sales_delivery',
      },
      {
        key: 'report_shipping_cost_per_expedition',
        label: reportmenu.shipping_cost_per_expedition,
        onClick: () => onNavigate('/reports/shipping-cost-per-expedition'),
        permission: 'report_shipping_cost_per_expedition',
      },
      {
        key: 'report_invoice_payment',
        label: reportmenu.invoice_payment,
        onClick: () => onNavigate('/reports/invoice-payment'),
        permission: 'report_invoice_payment',
      },
      {
        key: 'report_sales_per_product_category',
        label: reportmenu.sales_per_product_category,
        onClick: () => onNavigate('/reports/sales-per-product-category'),
        permission: 'report_sales_per_product_category',
      },
      {
        key: 'report_product_sales_per_customer',
        label: reportmenu.product_sales_per_customer,
        onClick: () => onNavigate('/reports/product-sales-per-customer'),
        permission: 'report_product_sales_per_customer',
      },
      {
        key: 'report_sales_per_period',
        label: reportmenu.sales_per_period,
        onClick: () => onNavigate('/reports/sales-per-period'),
        permission: 'report_sales_per_period',
      },
      {
        key: 'report_purchase_detail',
        label: reportmenu.purchase_detail,
        onClick: () => onNavigate('/reports/purchase-detail'),
        permission: 'report_purchase_detail',
      },
      {
        key: 'report_aged_payables',
        label: reportmenu.aged_payables,
        onClick: () => onNavigate('/reports/aged-payable'),
        permission: 'report_aged_payables',
      },
      {
        key: 'report_supplier_invoice',
        label: reportmenu.supplier_invoice,
        onClick: () => onNavigate('/reports/supplier-invoice'),
        permission: 'report_supplier_invoice',
      },
      {
        key: 'report_purchases_per_product',
        label: reportmenu.purchases_per_product,
        onClick: () => onNavigate('/reports/purchases-per-product'),
        permission: 'report_purchases_per_product',
      },
      {
        key: 'report_purchases_per_supplier',
        label: reportmenu.purchases_per_supplier,
        onClick: () => onNavigate('/reports/purchases-per-vendor'),
        permission: 'report_purchases_per_supplier',
      },
      {
        key: 'report_purchase_delivery',
        label: reportmenu.purchase_delivery,
        onClick: () => onNavigate('/reports/purchase-delivery'),
        permission: 'report_purchase_delivery',
      },
      {
        key: 'report_purchases_invoice_payment',
        label: reportmenu.purchases_invoice_payment,
        onClick: () => onNavigate('/reports/purchase-invoice-payment'),
        permission: 'report_purchase_invoice_payment',
      },
      {
        key: 'report_product_purchases_per_supplier',
        label: reportmenu.product_purchases_per_supplier,
        onClick: () => onNavigate('/reports/product-purchases-per-vendor'),
        permission: 'report_product_purchases_per_vendor',
      },
      {
        key: 'report_purchases_per_period',
        label: reportmenu.purchases_per_period,
        onClick: () => onNavigate('/reports/purchases-per-period'),
        permission: 'report_purchases_per_period',
      },
      {
        key: 'report_expense_per_contact',
        label: reportmenu.expense_per_contact,
        onClick: () => onNavigate('/reports/expense-per-contact'),
        permission: 'report_expense_per_contact',
      },
      {
        key: 'report_expense_claim_detail',
        label: reportmenu.expense_claim_detail,
        onClick: () => onNavigate('/reports/expense-claim'),
        permission: 'report_expense_claim_detail',
      },
      {
        key: 'report_sales_tax',
        label: reportmenu.sales_tax,
        onClick: () => onNavigate('/reports/sales-tax'),
        permission: 'report_sales_tax',
      },
      {
        key: 'report_witholding_tax',
        label: reportmenu.witholding_tax,
        onClick: () => onNavigate('/reports/witholding-tax'),
        permission: 'report_witholding_tax',
      },
      {
        key: 'report_inventory_summary',
        label: reportmenu.inventory_summary,
        onClick: () => onNavigate('/reports/inventory-summary'),
        permission: 'report_inventory_summary',
      },
      {
        key: 'report_inventory_stock_movement',
        label: reportmenu.inventory_stock_movement,
        onClick: () => onNavigate('/reports/inventory-stock-movement'),
        permission: 'report_inventory_stock_movement',
      },
      {
        key: 'report_warehouse_stock_summary',
        label: reportmenu.warehouse_stock_summary,
        onClick: () => onNavigate('/reports/warehouse-stock-summary'),
        permission: 'report_warehouse_stock_summary',
      },
      {
        key: 'report_warehouse_stock_movement',
        label: reportmenu.warehouse_stock_movement,
        onClick: () => onNavigate('/reports/warehouse-stock-movement'),
        permission: 'report_warehouse_stock_movement',
      },
      {
        key: 'report_production',
        label: reportmenu.production,
        onClick: () => onNavigate('/reports/production'),
        permission: 'report_production',
      },
      {
        key: 'report_report_inventory_stock_adjustment',
        label: reportmenu.report_inventory_stock_adjustment,
        onClick: () => onNavigate('/reports/inventory-stock-adjustment'),
        permission: 'report_report_inventory_stock_adjustment',
      },
      {
        key: 'report_inventory_warehouse_transfer',
        label: reportmenu.inventory_warehouse_transfer,
        onClick: () => onNavigate('/reports/warehouse-transfer'),
        permission: 'report_inventory_warehouse_transfer',
      },
      {
        key: 'report_fixed_asset_summary',
        label: reportmenu.fixed_asset_summary,
        onClick: () => onNavigate('/reports/fixed-asset-summary'),
        permission: 'report_fixed_asset_summary',
      },
      {
        key: 'report_fixed_asset_detail',
        label: reportmenu.fixed_asset_detail,
        onClick: () => onNavigate('/reports/fixed-asset-detail'),
        permission: 'report_fixed_asset_detail',
      },
      {
        key: 'report_fixed_asset_disposal',
        label: reportmenu.fixed_asset_disposal,
        onClick: () => onNavigate('/reports/fixed-asset-disposal'),
        permission: 'report_fixed_asset_disposal',
      },
      {
        key: 'report_budget',
        label: reportmenu.budget,
        onClick: () => onNavigate('/reports/budget-management'),
        permission: 'report_budget',
      },
      {
        key: 'report_budget_profit_loss',
        label: reportmenu.budget_profit_loss,
        onClick: () => onNavigate('/reports/budget-profit-loss'),
        permission: 'report_budget_profit_loss',
      },
      {
        key: 'report_attachment',
        label: reportmenu.attachment,
        onClick: () => onNavigate('/reports/attachment'),
        permission: 'report_attachment',
      },
      {
        key: 'report_export',
        label: reportmenu.export,
        onClick: () => onNavigate('/reports/export'),
        permission: 'report_export',
      },
      {
        key: 'report_import',
        label: reportmenu.import,
        onClick: () => onNavigate('/reports/import'),
        permission: 'report_import',
      },
    ]
    return menuItems.filter((item) => options.includes(item.key))
  }, [options, onNavigate, reportmenu])

  return (
    <DropdownClickButton
      menu={{ items }}
      buttonLeftProps={{
        children: formatMessage({ id: 'report.see_report' }),
        icon: <BarChartOutlined />,
      }}
      id="dropdown-click-report-preview"
      {...props}
    >
      <BarChartOutlined /> {formatMessage({ id: 'report.see_report' })}
    </DropdownClickButton>
  )
}

export default memo(ReportDropdown, compareWithJSON)
