import { message } from 'antd'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
// import { useDispatch, useSelector } from 'react-redux'
import {
  addFinanceJoinedInvoice,
  approveFinanceInvoice,
  changeFinanceInvoiceTaxNumber,
  deleteFinanceInvoice,
  deleteFinanceJoinedInvoice,
  editFinanceJoinedInvoice,
  executeImportFinanceInvoiceRecurring,
  executeImportFinanceInvoiceReturn,
  executeImportStatusFinanceInvoice,
  executeMassDeleteRecurringFinanceInvoice,
  getFinanceInvoice,
  getFinanceInvoicePublicUrl,
  getFinanceInvoices,
  getFinanceJoinedInvoice,
  getMinTransDateFinanceInvoice,
  getRecurringInvoices,
  getTotalInvoices,
  inputMassCreateFinanceInvoice,
  inputMassDeleteRecurringFinanceInvoice,
  rejectFinanceInvoice,
  revertApprovalFinanceInvoice,
  stampingInvoice,
  unvoidFinanceInvoice,
  uploadImportFinanceInvoiceRecurring,
  uploadImportFinanceInvoiceReturn,
  uploadImportStatusFinanceInvoice,
  voidFinanceInvoice,
} from 'utils/apis'
// import { optionsSelector } from 'redux/selectors'
// import { editOption } from 'redux/options/actions'

export const useInvoices = ({
  payload = {},
  // additionalQueryKey,
  enabled = false,
  // saveDataPagination = true,
  options = {},
}) => {
  // const optionsState = useSelector(optionsSelector)
  // const dispatch = useDispatch()

  // if (saveDataPagination) {
  //   // prevent user that already setting per_page > 100
  //   if (payload.per_page > 100) {
  //     payload.per_page = 100
  //   }
  //   if (payload.per_page && payload.per_page !== optionsState.sales_per_page_default) {
  //     dispatch(
  //       editOption({
  //         sales_per_page_default: payload.per_page,
  //       }),
  //     )
  //   }
  // }

  return useQuery(
    ['invoice', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceInvoices({ ...payload, per_page: payload.per_page })
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useRecurringInvoices = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['invoice', 'recurring', payload],
    async () => {
      const {
        data: { data },
      } = await getRecurringInvoices(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export function useInvoice({ id, payload = {}, enabled = false }) {
  return useQuery(
    ['invoice', id, payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceInvoice(id, payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export function useInvoicePublicUrl(id, options = {}) {
  return useQuery(
    ['invoicePublicUrl', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceInvoicePublicUrl(id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useDeleteInvoice = () => {
  return useMutation((payload) => deleteFinanceInvoice(payload.id))
}

export const useChangeInvoiceTaxNumber = () => {
  return useMutation((payload) => changeFinanceInvoiceTaxNumber(payload.id, payload))
}

export const useApproveInvoice = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => approveFinanceInvoice({ id }), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data.message)
        queryClient.invalidateQueries(['invoice', `${response.data.data.id}`, {}])
      } else {
        message.error(response.data.message)
      }
      // queryClient.invalidateQueries(['invoice', `${response.data.data.id}`, {}], response.data.data)
      // message.success(response.data.message)
    },
    onError: (error) => message.error(error.data.message),
  })
}

export const useRejectInvoice = () => {
  const queryClient = useQueryClient()
  return useMutation((payload) => rejectFinanceInvoice(payload), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data.message)
        queryClient.invalidateQueries(['invoice', `${response.data.data.id}`, {}])
      } else {
        message.error(response.data.message)
      }
      // recreate object to trigger useMemo rerender on data change
      // const oldData = {
      //   ...queryClient.getQueryData(['invoice', `${response.data.data.id}`, {}]),
      // }
      // oldData.is_approveable = false
      // oldData.approval_status.is_rejected = 1
      // queryClient.setQueryData(['invoice', `${oldData.id}`, {}], oldData)
      // message.success(response.data.message)
    },
    onError: (error) => message.error(error.data.message),
  })
}

export const useRevertApprovalFinanceInvoice = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => revertApprovalFinanceInvoice({ id }), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data.message)
        queryClient.invalidateQueries(['invoice', `${response.data.data.id}`, {}])
      } else {
        message.error(response.data.message)
      }
    },
    onError: (error) => message.error(error.data.message),
  })
}

export const useUploadImportFinanceInvoiceReturn = () => {
  return useMutation((payload) => uploadImportFinanceInvoiceReturn(payload))
}

export const useExecuteImportFinanceInvoiceReturn = () => {
  return useMutation((payload) => executeImportFinanceInvoiceReturn(payload))
}

export const useVoidInvoice = () => {
  return useMutation((payload) => voidFinanceInvoice(payload.id, payload.body))
}

export const useUnvoidInvoice = () => {
  return useMutation((payload) => unvoidFinanceInvoice(payload.id))
}

export const useUploadImportFinanceInvoiceRecurringMutation = () => {
  return useMutation(uploadImportFinanceInvoiceRecurring)
}

export const useExecuteImportFinanceInvoiceRecurringMutation = () => {
  return useMutation(executeImportFinanceInvoiceRecurring)
}

export const useUploadImportStatusFinanceInvoiceMutation = () => {
  return useMutation(uploadImportStatusFinanceInvoice)
}

export const useExecuteImportStatusFinanceInvoiceMutation = () => {
  return useMutation(executeImportStatusFinanceInvoice)
}

export const useGetMinTransDateFinanceInvoiceMutation = () => {
  return useMutation((payload) =>
    getMinTransDateFinanceInvoice({ ...payload, trans_type: 'invoice' }),
  )
}

export const useInputMassCreateFinanceInvoiceMutation = () => {
  return useMutation(inputMassCreateFinanceInvoice)
}

export const useStampingInvoice = () => {
  return useMutation((payload) => stampingInvoice(payload.id, payload.body))
}

export const useTotalInvoices = (params, options) => {
  return useQuery(
    ['total-invoices', params],
    async () => {
      const {
        data: { data },
      } = await getTotalInvoices(params)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useInputMassDeleteRecurringFinanceInvoiceMutation = () => {
  return useMutation(inputMassDeleteRecurringFinanceInvoice)
}

export const useExecuteMassDeleteRecurringFinanceInvoiceMutation = () => {
  return useMutation(executeMassDeleteRecurringFinanceInvoice)
}

export const useInfiniteInvoiceSuggestion = (payload, extraQueryKey, options = {}) => {
  return useInfiniteQuery(
    ['infinite/invoices/suggestion', payload, extraQueryKey],
    async ({ pageParam = 0 }) => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceInvoices({ page: pageParam, ...payload })
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined
        }
        return lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : undefined
      },
      onError: (error) => message.error(error.message || 'Failed to load data from server!'),
      keepPreviousData: true,
      ...options,
    },
  )
}

export function useJoinedInvoice({ payload = {}, enabled = false }) {
  return useQuery(
    ['joined-invoice', payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceJoinedInvoice(payload.id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export const useAddJoinedInvoice = () => {
  return useMutation((payload) => addFinanceJoinedInvoice(payload))
}

export const useEditJoinedInvoice = () => {
  return useMutation((payload) => editFinanceJoinedInvoice(payload))
}

export const useDeleteJoinedInvoice = () => {
  return useMutation((id) => deleteFinanceJoinedInvoice(id))
}
